import React, { lazy, Suspense } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import BoxTransport from "../components/box-transport";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

const Spline = lazy(() => import("@splinetool/react-spline"));

const ComeArrivare = ({ data }) => {
  const { transport, fondazione } = data;

  return (
    <Layout>
      <Seo
        title={`Come arrivare`}
        description={`Come arrivare a Palazzo Giureconsulti: mezzi pubblici, treno, auto ed aereo`}
      />

      <div className="box-intro box-intro--page-name">
        <Suspense>
          <Spline
            scene="https://prod.spline.design/nzpMEFQ6p2yPBhq5/scene.splinecode"
            className="animation-circle"
          />
        </Suspense>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="box-intro__page-name">
                  <span>Come</span> arrivare
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section section--grey-dark">
        <div className="box-article">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-xl-7 offset-xl-1">
                <h2 className="box-article__title">Palazzo Giureconsulti</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-11 offset-xl-1">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6">
                    <div className="box-article__text">
                      <p>
                        Piazza Mercanti, 2
                        <br />
                        20121 Milano
                      </p>
                      <p>
                        +39 02 8515.5920
                        <br />
                        infogiureconsulti@mi.camcom.it
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <GatsbyImage
                      image={getImage(fondazione)}
                      alt="img background help"
                      objectPosition="center center"
                      className="box-article__img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BoxTransport items={transport} />

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default ComeArrivare;

export const query = graphql`
  query {
    transport: transportJson {
      transport {
        title
        icon
        text
      }
    }
    fondazione: file(relativePath: { eq: "location/5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
  }
`;
