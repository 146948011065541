import React from "react";

import BusIcon from "../icons/bus-thin.svg";
import TrainIcon from "../icons/train-light.svg";
import CarIcon from "../icons/car-thin.svg";
import PlaneIcon from "../icons/plane-thin.svg";

const BoxTransport = ({ items }) => {
  return (
    <section className="section">
      <div
        className="section__bg-title section__bg-title--small text-center text-uppercase"
        aria-hidden="true"
      >
        Come arrivare
      </div>
      <div className="container-md">
        <div className="row">
          {items.transport.map((item, i) => (
            <div key={i} className="col-12 col-md-6">
              <div className="box-transport">
                <div className="box-transport__icon">
                  {item.icon === "bus" && <BusIcon className="ico-svg" />}
                  {item.icon === "train" && <TrainIcon className="ico-svg" />}
                  {item.icon === "car" && <CarIcon className="ico-svg" />}
                  {item.icon === "plane" && <PlaneIcon className="ico-svg" />}
                </div>
                <div className="box-transport__title text-uppercase">{item.title}</div>
                <div
                  dangerouslySetInnerHTML={{ __html: item.text }}
                  className="box-transport__text"
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BoxTransport;
